$primaryColor: #ffbb00;
$secondaryColor: #00a398;

$lightBackground: #fff;
$darkBackground: #181b1b;
$darkPaperBackground: #101718;

$parkingLoad0to20Color: #0c0;
$parkingLoad20to40Color: #099;
$parkingLoad40to60Color: #ffce00;
$parkingLoad60to80Color: #ff7400;
$parkingLoad80to100Color: #a60000;

$parkingPlaceCountColor: #0E4DA4;

$parkingSessionStatusActiveColor: #1BB55C;
$parkingSessionStatusPendingColor: #ffbb00;
$parkingSessionStatusPenalizedColor: #E74C3C;
$parkingSessionStatusEvacuatedColor: #0E4DA4;
$parkingSessionStatusPaidColor: #a4a8a8;