@import '../../styles/Colors.scss';

.root {
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
  background-color: $primaryColor;
  &.dark {
    background-color: $darkBackground;
  }
  @media screen and (prefers-color-scheme: dark) {
    background-color: $darkBackground;
    &.light {
      background-color: $primaryColor;
    }
  }
}