@import '../../styles/Colors.scss';

.logoRoot {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
}

.logoImage {
  height: 100%;
}

.logoText {
  margin-left: 0.5em;
  font-size: 24px;
  line-height: 1em;
  font-weight: 800;
  color: black;
  &.dark {
    color: $primaryColor;
  }
}
