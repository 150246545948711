@import './styles/Breakpoints.scss';

* {
  box-sizing: border-box;
}

html {
  font-size: 16px;
  @media screen and (min-width: $mdMin) and (max-width: $lgMax) {
    font-size: 18px;
  }
  @media screen and (min-width: $xlMin) {
    font-size: 20px;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
  overscroll-behavior: contain;
}